import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  LinearProgress,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Message from "./Message";
import OpenAI from "openai";
import { MessageDto } from "./MessageDto";
import SendIcon from "@mui/icons-material/Send";
import { utils, writeFile } from "xlsx";

const config = require("./openaiConfig");

const Chat = () => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [assistant, setAssistant] = useState(null);
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  const [assistantList, setAssistantList] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("txt");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    if (assistantList.length > 0) {
      setSelectedAssistant(assistantList[0].id);
    }
  }, [assistantList]);

  useEffect(() => {
    initChatBot();
  }, [selectedAssistant]);

  useEffect(() => {
    if (assistant) {
      logMessage(
        `Assistant: Hi, I'm your personal ${assistant.name}. How can I help you?`
      );
      setMessages([
        {
          content: `Hi, I'm your personal ${assistant.name}. How can I help you?`,
          isUser: false,
        },
      ]);
    }
  }, [assistant]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchAssistants = async () => {
    try {
      const openai = new OpenAI({
        apiKey: config.OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const assistants = await openai.beta.assistants.list();

      setAssistantList(assistants.data);
      setOpenai(openai);
    } catch (error) {
      console.error("Error fetching assistants:", error.message);
      toast.error("Failed to fetch assistants.");
    }
  };

  const initChatBot = async () => {
    try {
      const selected = assistantList.find(
        (assistant) => assistant.id === selectedAssistant
      );
      if (!selected) return;

      const openai = new OpenAI({
        apiKey: config.OPENAI_API_KEY,
        assistantId: selected.id,
        dangerouslyAllowBrowser: true,
      });

      const thread = await openai.beta.threads.create();

      setOpenai(openai);
      setAssistant(selected);
      setThread(thread);
    } catch (error) {
      console.error("Error initializing assistant:", error.message);
      toast.error("Failed to initialize assistant.");
    }
  };

  const createNewMessage = (content, isUser) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const logMessage = (content) => {
    // Log messages to console or wherever you want
    console.log(content);
  };

  const handleSendMessage = async () => {
    try {
      // Check if input is empty
      if (!input.trim()) {
        throw new Error("Message content must be non-empty.");
      }

      // Log user's message
      const userMessage = `User: ${input}`;
      logMessage(userMessage);

      // Add user's message to the messages array
      messages.push(createNewMessage(input, true));
      setMessages([...messages]);
      setInput("");

      // Send user's message to the thread
      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: input,
      });

      // Run the assistant
      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistant.id,
      });

      // Retrieve the response from the assistant
      let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

      // Wait for the response to be ready
      while (
        response.status === "in_progress" ||
        response.status === "queued"
      ) {
        setIsWaiting(true);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      }

      setIsWaiting(false);

      // Get the messages for the thread
      const messageList = await openai.beta.threads.messages.list(thread.id);

      // Find the last message from the assistant
      const lastAssistantMessage = messageList.data
        .filter(
          (message) => message.run_id === run.id && message.role === "assistant"
        )
        .pop();

      // Add the assistant's response to the messages array
      if (lastAssistantMessage) {
        const assistantResponse = `Assistant: ${lastAssistantMessage.content[0]["text"].value}`;
        logMessage(assistantResponse);
        setMessages([
          ...messages,
          createNewMessage(
            lastAssistantMessage.content[0]["text"].value,
            false
          ),
        ]);
      }
    } catch (error) {
      // Handle errors
      console.error("Error sending message:", error.message);
      toast.error(error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleAssistantChange = (event) => {
    setSelectedAssistant(event.target.value);
  };

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const downloadChatHistory = () => {
    const formattedDate = new Date()
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
    const extension = selectedFormat === "txt" ? "txt" : "xlsx";
    const fileName = `${formattedDate}_chat_history.${extension}`;

    if (selectedFormat === "txt") {
      // Download as text file
      downloadAsText(fileName);
    } else if (selectedFormat === "excel") {
      // Download as Excel file
      downloadAsExcel(fileName);
    }
  };

  const downloadAsText = (fileName) => {
    // Format messages as text
    const formattedMessages = messages
      .map((message) => {
        return `${message.isUser ? "User" : "Assistant"}: ${message.content}`;
      })
      .join("\n");

    // Create a Blob with the formatted text
    const file = new Blob([formattedMessages], { type: "text/plain" });

    // Trigger download
    triggerDownload(file, fileName);
  };

  const downloadAsExcel = (fileName) => {
    // Prepare data for Excel
    const data = messages.map((message, index) => {
      // Extract links from the message content
      const links = extractLinks(message.content);
      // Remove links from the message content
      const contentWithoutLinks = removeLinks(message.content);
      return [
        index + 1,
        message.isUser ? "User" : "Assistant",
        contentWithoutLinks,
        links.join(", "),
      ];
    });

    // Create a new Excel workbook
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([
      ["Message No.", "Sender", "Content", "Links"],
      ...data,
    ]);
    utils.book_append_sheet(wb, ws, "Chat History");

    // Save the workbook as a file
    writeFile(wb, fileName);
  };

  // Function to extract links from a string
  const extractLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex) || [];
  };

  // Function to remove links from a string
  const removeLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, "").trim();
  };

  const triggerDownload = (file, fileName) => {
    // Create a temporary link element
    const element = document.createElement("a");

    // Set the href attribute to the Blob URL
    element.href = URL.createObjectURL(file);

    // Set the download attribute to the file name
    element.download = fileName;

    // Append the element to the document body
    document.body.appendChild(element);

    // Trigger the click event to initiate download
    element.click();

    // Remove the element from the document body
    document.body.removeChild(element);
  };

  return (
    <Container>
      <Grid container direction="column" spacing={2}>
        {messages.map((message, index) => (
          <Grid
            item
            key={index}
            alignSelf={message.isUser ? "flex-end" : "flex-start"}
          >
            <Message key={index} message={message} />
          </Grid>
        ))}
        <div ref={messagesEndRef} />
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            label="Type your message"
            variant="outlined"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={isWaiting || !selectedAssistant}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSendMessage}
            disabled={isWaiting || !selectedAssistant}
          >
            {isWaiting ? <CircularProgress color="inherit" /> : <SendIcon />}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={selectedAssistant}
            onChange={handleAssistantChange}
            fullWidth
            disabled={isWaiting}
          >
            {assistantList.map((assistant) => (
              <MenuItem key={assistant.id} value={assistant.id}>
                {assistant.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            value={selectedFormat}
            onChange={handleFormatChange}
            disabled={messages.length === 0}
          >
            <MenuItem value="txt">Text (TXT)</MenuItem>
            <MenuItem value="excel">Excel (XLSX)</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadChatHistory}
            disabled={messages.length === 0}
          >
            Download Chat History
          </Button>
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  );
};

export default Chat;
