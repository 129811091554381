import React, { Component } from "react";
import Tree from "react-d3-tree";
import "./styles.css";
import Legion from "./legion";
import HoverComponent from "./HoverComponent";
import AttributesPanel from "../AttributesPanel";
import { withRouter } from "react-router-dom";

import {
  appendNodes,
  popNodes,
  quickCheckAccess,
} from "../../utils/accessMapFunctions";

import { uuidv4 } from "../../utils/utilities";
import { getEdges, getNodes } from "../../actions";

import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlineExpandAlt,
  AiFillCloseCircle,
} from "react-icons/ai";

import { FiRefreshCw } from "react-icons/fi";
import { getProcessAttributesByPartialName } from "../../actions";

function handleEnterFullscreen() {
  let elem = document.getElementById("graph");

  if (elem.requestFullscreen) {
    try {
      elem.requestFullscreen();
    } catch (err) {
      console.error(err);
    }
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    try {
      elem.webkitRequestFullscreen();
    } catch (err) {
      console.error(err);
    }
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    try {
      elem.msRequestFullscreen();
    } catch (err) {
      console.error(err);
    }
  }
}

/* Close fullscreen */
function handleExitFullscreen() {
  if (document.exitFullscreen) {
    try {
      document.exitFullscreen();
    } catch (err) {
      console.error(err);
    }
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    try {
      document.webkitExitFullscreen();
    } catch (err) {
      console.error(err);
    }
  } else if (document.msExitFullscreen) {
    /* IE11 */
    try {
      document.msExitFullscreen();
    } catch (err) {
      console.error(err);
    }
  }
}

var factSheetShowTimeout, hoverTimeOut;

class CustomD3Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.nodeDatum
        ? this.props.nodeDatum
        : {
            id: uuidv4(),
            guid: this.props.e2eMap?.GUID,
            shortName: this.getShortName(this.props.e2eMap?.name),
            fullName: this.props.e2eMap?.name,
            type: this.props.e2eMap?.Type,
            description: this.props.e2eMap?.Description,
            children: [],
            isLink: false,
            inTrial: true,
            url: "",
            style: {
              background: "#FFFFFF",
            },
          },
      nodeDatum: { id: "", guid: "" },
      show: false,
      x: 0,
      y: 0,
      MouseOnNode: false,
      dimensions: undefined,
      translate: {
        x: 400,
        y: 200,
      },
      nodeSize: { x: 450, y: 60 },
      isFullScreen: false,
      zoom: 0.6,
      attributes: undefined,
      occurences: undefined,
      hasSearchResult: undefined,
      expandedPropertyPannel: false,
      relationships: undefined,
    };
  }

  straightPathFunc = (linkDatum, orientation) => {
    const { source, target } = linkDatum;
    return orientation === "horizontal"
      ? `M${source.y + 220},${source.x} L${target.y},${target.x}`
      : `M${source.x},${source.y}L${target.x},${target.y}`;
  };

  getDynamicPathClass = ({ source, target }, orientation) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return "link__to-leaf";
    }

    // Style it as a link connecting two branch nodes by default.
    return "link__to-branch";
  };

  checkAccessibility(nodeDatum) {
    if (["E2E-Map"].includes(nodeDatum.type)) return true;
    if (nodeDatum.isLink) return true;
    if (
      nodeDatum &&
      quickCheckAccess(nodeDatum?.inTrial, this.props.user.subscription)
    )
      return true;
    else return false;
  }

  instantiateDefaultNode = () => {
    const defaultNode = {
      id: uuidv4(),
      guid: this.props.e2eMap?.GUID,
      shortName: this.getShortName(this.props.e2eMap?.name),
      fullName: this.props.e2eMap?.name,
      type: this.props.e2eMap?.Type,
      description: this.props.e2eMap?.Description,
      children: [],
      isLink: false,
      inTrial: true,
      url: "",
      style: {
        background: "#FFFFFF",
      },
    };

    this.setState({ data: defaultNode });
  };

  centralizeNodes() {
    if (this.treeContainer) {
      const dimensions = this.treeContainer.getBoundingClientRect();

      if (this.state.dimensions === undefined) {
        this.setState({
          dimensions: {
            width: dimensions.width,
            height: dimensions.height,
          },
          translate: {
            x: dimensions.width / 2.5,
            y: dimensions.height / 2,
          },
        });
      }

      this.setState({
        dimensions: {
          width: dimensions.width,
          height: dimensions.height,
        },
      });
    }
    //toggleNode();
  }

  async componentDidMount() {
    this.centralizeNodes();
    let GUID = this.props.location.pathname.split("/").pop();
    let response = await getProcessAttributesByPartialName(GUID);

    this.setState({
      data: {
        id: uuidv4(),
        guid: GUID,
        shortName: this.getShortName(response.content?.name),
        fullName: response.content?.name,
        type: response.content?.Type,
        description: response.content?.Description,
        children: [],
        isLink: false,
        inTrial: true,
        url: "",
        style: {
          background: "#FFFFFF",
        },
      },
    });
  }

  _onMouseMove(e) {
    if (this.state.MouseOnNode) {
      this.setState({ x: e.pageX, y: e.pageY });
    }
  }

  handleShow(nodeDatum) {
    this.setState({ nodeDatum, show: true, MouseOnNode: true });
  }

  handleClose() {
    if (this.state.show)
      hoverTimeOut = setTimeout(() => {
        this.setState({ show: false });
      }, 300);
  }

  renderNodeWithCustomEvents = ({ nodeDatum, toggleNode }, appState) => (
    <g>
      <foreignObject on x="0" y="-60" height="150px" width="500px">
        <div
          className="elemental-node"
          style={{
            ...nodeDatum.style,
            display: "flex",
            flexDirection: "row",
          }}
          onClick={() => {
            if (this.checkAccessibility(nodeDatum)) {
              this.addChildNode(nodeDatum);
              this.centralizeNodes();
              toggleNode();
            }
          }}
          onMouseOver={() => {
            factSheetShowTimeout = setTimeout(
              () => this.handleShow(nodeDatum),
              700
            );
          }}
          onMouseOut={() => {
            clearTimeout(factSheetShowTimeout);
            this.handleClose();
          }}
        >
          <span className="elemental-name">{nodeDatum.shortName}</span>
          <div style={{ color: "red", marginLeft: 10 }}>
            {nodeDatum?.total ? nodeDatum.total : ""}
          </div>
        </div>
      </foreignObject>
    </g>
  );

  instantiateNode(nodeDatum) {
    this.setState({
      data: nodeDatum,
    });
  }

  addChildNode = async (nodeDatum) => {
    /** Override past content */
    /*  
    const depth = nodeDatum.__rd3t.depth + 1
    var nextData = [];
    findNode(nodeDatum.id, { ...this.state.data }, nextData)
    const target = nextData[0].children;
    */

    //** Keep past content */
    const depth = nodeDatum.__rd3t.depth + 1;
    var nextData = { ...this.state.data };
    let newNodes = [];

    //** In case of tree already open, close & pop child nodes */

    if (nodeDatum?.children.length > 0) {
      popNodes(nodeDatum.id, nextData);
      return this.setState({
        data: nextData,
      });
    }

    if (!nodeDatum.isLink) {
      if (this.props.user.subscription === "trial" && !nodeDatum.inTrial) {
        alert("this content is not yet available for trial version!");
        return;
      }
      const edges = await getEdges(nodeDatum.guid);

      for (let edge of edges) {
        newNodes.push({
          id: uuidv4(),
          guid: nodeDatum.guid,
          shortName: this.getShortName(edge.type),
          fullName: edge.type,
          //type: edge.name,
          total: edge.total,
          children: [],
          isLink: true,
          style: {
            background: "#FFFFFF",
            border: "none",
          },
        });
      }
    } else {
      const nodes = await getNodes(nodeDatum.guid, nodeDatum.fullName);
      for (let node of nodes) {
        newNodes.push({
          id: uuidv4(),
          guid: node.GUID,
          shortName: this.getShortName(node.name),
          fullName: node.name,
          type: node.Type,
          description: node.Description,
          children: [],
          isLink: false,
          inTrial: node.inTrial === true,
          url: node?.URL,
          style: {
            //background: "#FFFFFF",
            backgroundColor: quickCheckAccess(
              node.inTrial,
              this.props.user.subscription
            )
              ? "#FFFFFF"
              : "#d3d3d3",
            cursor: quickCheckAccess(node.inTrial, this.props.user.subscription)
              ? "pointer"
              : "not-allowed",
            opacity: quickCheckAccess(
              node.inTrial,
              this.props.user.subscription
            )
              ? 1
              : 0.5,
          },
        });
      }
    }

    appendNodes(nodeDatum.id, nextData, newNodes);

    this.setState({
      data: nextData,
    });
  };

  removeChildNode = () => {
    const nextData = { ...this.state.data };
    const target = nextData.children;
    target.pop();
    this.injectedNodesCount--;
    this.setState({
      data: nextData,
    });
  };

  getShortName(name, limit_) {
    const limit = limit_ || 25;
    return name?.length > limit ? name?.substr(0, limit) + ".." : name;
  }
  //update attributes for property pannel
  updateAttributes = (newAttributes) => {
    this.setState({ attributes: newAttributes });
  };
  //update occurances for property pannel
  updateOccurences = (newOccurences) => {
    this.setState({ occurences: newOccurences });
  };
  //update relationships for property pannel
  updateRelationships = (newRelationships) => {
    this.setState({ relationships: newRelationships });
  };
  //update visible state of prorperty pannel
  updateExpandedPropertyPannel = (newStateOfProprtyPannel) => {
    this.setState({ expandedPropertyPannel: newStateOfProprtyPannel });
  };
  //click on Occurences from property pannel
  handleButtonClickOccurences = (occGUID) => {
    const { history } = this.props;
    history.push(`/${this.props.user.mainDatabase}/object/${occGUID}`);
    history.go();
  };
  //click on relationships from property pannel
  handleButtonClickRelationships = (relGUID) => {
    const { history } = this.props;
    history.push(`/${this.props.user.mainDatabase}/object/${relGUID}`);
    history.go();
  };

  render() {
    const { x, y } = this.state;

    return (
      <React.Fragment>
        <div className="graph-legion">
          <Legion />
        </div>
        {/* Proprty Pannel */}
        <div className="property-pannel ">
          <div
            className="expand-collapse"
            onClick={() =>
              this.setState({
                expandedPropertyPannel: !this.state.expandedPropertyPannel,
              })
            }
          >
            {this.state?.expandedPropertyPannel ? (
              <AiFillCloseCircle title="Close" />
            ) : (
              <AiOutlineExpandAlt title="Show properties pannel " />
            )}
          </div>

          {/* {hasSearchResult ? ( */}
          {this.state.expandedPropertyPannel ? (
            <div
              // className="panel-group"
              style={{
                // maxHeight: "73vh",
                // minHeight: "73vh",
                display: "flex",
              }}
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h5 className="panel-title"></h5>
                </div>
                {/* <div id="collapse1" className="collapseDiv">> */}
                <div>
                  <div>
                    <h5
                      style={{
                        color: "#222",
                        fontWeight: "bold",
                      }}
                    >
                      Properties
                    </h5>
                  </div>

                  <ul className="nav nav-pills">
                    <li
                      style={{
                        fontStyle: "italic",
                        marginRight: "5px",
                      }}
                    >
                      <a
                        className="active"
                        data-toggle="tab"
                        href="#attributes"
                      >
                        Attributes
                      </a>
                    </li>
                    |
                    <li
                      style={{
                        fontStyle: "italic",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <a data-toggle="tab" href="#occurence">
                        Occurrences
                      </a>
                    </li>
                    |
                    <li
                      style={{
                        fontStyle: "italic",
                        marginLeft: "5px",
                      }}
                    >
                      <a data-toggle="tab" href="#relationship">
                        Relationships
                      </a>
                    </li>
                  </ul>

                  <div
                    className="tab-content"
                    style={{
                      maxHeight: "63vh",
                      border: "2px solid #000000",
                      overflow: "scroll",
                      width: "22vw",
                    }}
                  >
                    <div id="attributes" className="tab-pane active">
                      {this.state.attributes ? (
                        <AttributesPanel attributes={this.state.attributes} />
                      ) : (
                        <h5
                          style={{
                            marginLeft: "15px",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          {" "}
                          ... No Attributes Found ...
                        </h5>
                      )}
                    </div>
                    <div id="occurence" className="tab-pane fade">
                      <div
                        className="table-responsive-xl"
                        style={{ tableLayout: "fixed" }}
                      >
                        <ul className="list-group">
                          <li className="list-group-item">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="attribute-name" scope="col">
                                    Object Occurences
                                  </th>
                                </tr>
                              </thead>
                            </table>
                            {this.state.occurences ? (
                              <div>
                                {this.state.occurences.map((occ) => (
                                  <div key={uuidv4()}>
                                    <div
                                      style={{
                                        textDecoration: "underline",
                                        textUnderlinePosition: "under",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleButtonClickOccurences(
                                          occ.GUID
                                        )
                                      }
                                    >
                                      {" "}
                                      {occ.name}{" "}
                                    </div>
                                    <div
                                      key={"t" + occ.GUID}
                                      style={{
                                        fontSize: "1.3vh",
                                        marginTop: "1vh",
                                      }}
                                    >
                                      {occ.Type}
                                    </div>
                                    <hr
                                      style={{
                                        width: "50",
                                        textAlign: "left",
                                        marginLeft: "0",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h5> ... No Occurences Found ...</h5>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="relationship" className="tab-pane fade">
                      <div className="table-responsive-xl">
                        <ul className="list-group">
                          <li className="list-group-item">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="attribute-name" scope="col">
                                    Object Relationships
                                  </th>
                                </tr>
                              </thead>
                            </table>
                            {this.state?.relationships ? (
                              <div>
                                {this.state?.relationships.map((rel) => (
                                  <div key={uuidv4()}>
                                    <div
                                      href="#"
                                      style={{
                                        textDecoration: "underline",
                                        textUnderlinePosition: "under",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault(); // Prevent default action
                                        this.handleButtonClickRelationships(
                                          rel.GUID
                                        );
                                      }}
                                    >
                                      {" "}
                                      {rel.name}{" "}
                                    </div>
                                    <div
                                      key={"t" + rel.GUID}
                                      style={{
                                        fontSize: "1.3vh",
                                        marginTop: "1vh",
                                      }}
                                    >
                                      {rel.Type}
                                    </div>
                                    <hr
                                      style={{
                                        width: "50",
                                        textAlign: "left",
                                        marginLeft: "0",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <h5> ... No Relationships Found ...</h5>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* actual explorer  */}
        <div
          ref={(tc) => (this.treeContainer = tc)}
          className="graph"
          id="graph"
        >
          <HoverComponent
            {...this.props}
            show={this.state.show && !this.state.nodeDatum.isLink}
            nodeDatum={this.state?.nodeDatum}
            x={this.state.x}
            y={this.state.y}
            e2emap={this?.props.e2eMap}
            processLibrary={this.props.processLibrary}
            attributes={this.state.attributes}
            updateAttributes={this.updateAttributes}
            updateRelationships={this.updateRelationships}
            updateOccurences={this.updateOccurences}
            updateExpandedPropertyPannel={this.updateExpandedPropertyPannel}
            expandedPropertyPannel={this.state.expandedPropertyPannel}
          />

          <div
            name="menu-bar"
            className="noselect"
            style={{
              backgroundColor: "lightGrey",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            |
            <div
              title="drag left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  translate: {
                    x: this.state.translate.x - 100,
                    y: this.state.translate.y,
                  },
                });
              }}
            >
              <AiOutlineArrowLeft />
            </div>
            |
            <div
              title="drag right"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  translate: {
                    x: this.state.translate.x + 100,
                    y: this.state.translate.y,
                  },
                });
              }}
            >
              <AiOutlineArrowRight />
            </div>
            |
            <div
              title="restore to default"
              style={{ cursor: "pointer" }}
              onClick={(props) => {
                this.instantiateDefaultNode();
              }}
            >
              <FiRefreshCw />
            </div>
            |
            <div
              style={{ cursor: "pointer" }}
              title="zoom in"
              onClick={(props) => {
                this.setState({
                  zoom:
                    this.state.zoom < 1.5
                      ? this.state.zoom + 0.1
                      : this.state.zoom,
                });
              }}
            >
              <AiOutlineZoomIn />
            </div>
            |
            <div
              style={{ cursor: "pointer" }}
              title="zoom out"
              onClick={(props) => {
                this.setState({
                  zoom:
                    this.state.zoom > 0.25
                      ? this.state.zoom - 0.1
                      : this.state.zoom,
                });
              }}
            >
              <AiOutlineZoomOut />
            </div>
            |
            <div
              title={
                this.state.isFullScreen ? "exit full screen" : "full screen"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                try {
                  if (this.state.isFullScreen) {
                    handleExitFullscreen();
                  } else {
                    handleEnterFullscreen();
                  }
                  this.setState({ isFullScreen: !this.state.isFullScreen });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {this.state.isFullScreen ? (
                <AiOutlineFullscreenExit />
              ) : (
                <AiOutlineFullscreen />
              )}
            </div>
            |
          </div>

          <Tree
            style={{ backgroundColor: "white" }}
            data={this.state.data}
            renderCustomNodeElement={(rd3tProps) =>
              this.renderNodeWithCustomEvents({ ...rd3tProps })
            }
            translate={this.state.translate}
            dimensions={this.state.dimensions}
            zoom={this.state.zoom}
            orientation="horizontal"
            //pathFunc="step" //"eblow"
            nodeSize={this.state.nodeSize}
            scaleExtent={{ min: 0.25, max: 1.5 }}
            separation={{ siblings: 1, nonSiblings: 1.5 }}
            //shouldCollapseNeighborNodes={true}
            enableLegacyTransitions={false}
            // initialDepth={0}
            pathClassFunc={this.getDynamicPathClass}
            pathFunc={this.straightPathFunc}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(CustomD3Tree);
