import React, {
  AsyncStorage,
  Component,
  useEffect,
  useState,
  useRef,
} from "react";
import { InputGroup } from "react-bootstrap";
import ItemCard from "./ItemCard";
import ReactDOM, { render } from "react-dom";
import "../../index.css";
import PaginateAdvance from "./PaginateAdvanceSearch";
import { ButtonGroup, Button } from "react-bootstrap";
import { getAdvanceSearchFilter } from "../../actions";
import { TextField } from "@material-ui/core";
import AttributesPanel from "../AttributesPanel";
import { uuidv4 } from "../../utils/utilities";
import { useHistory } from "react-router-dom";
import { sortByProperty } from "../../utils/accessMapFunctions";

export default function AdvanceSearch(props) {
  const [types, setTypes] = useState([]);
  const [processTypes, setprocessTypes] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [checkedTypes, setCheckedTypes] = useState([]);
  const [sort, setSort] = useState("ASC");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [attributes, setAttributes] = useState(null);
  const [occurences, setOccurences] = useState(null);
  const [hasSearchResult, setHasSearchResult] = useState(null);
  const [relationships, setRelationships] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const processType = await getAdvanceSearchFilter();
        const allowedTypes = processType.filter((item) => item.display);
        setprocessTypes(allowedTypes, "false");
      } catch (error) {
        console.error("Failed to fetch advance search filter:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      if (occurences?.length > 0) {
        setOccurences(occurences.sort(sortByProperty("name")));
      }
    if (relationships?.length > 0) {
      setRelationships(relationships.sort(sortByProperty("name")));
    }

    return () => (isMounted = false);
  }, [occurences, relationships]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setCheckedTypes(processTypes);
    } else {
      setCheckedTypes([]);
    }
  };

  const handleCheckBox = (event) => {
    var updatedList = [...checkedTypes];
    if (event.target.checked) {
      updatedList = [...checkedTypes, event.target.value];
    } else {
      updatedList.splice(checkedTypes.indexOf(event.target.value), 1);
    }
    setCheckedTypes(updatedList);
  };

  return (
    <div className="container-fluid" style={{}}>
      <div
        className="row"
        style={{ justifyContent: "start", position: "relative" }}
      >
        <div className="col-12" style={{ display: "flex" }}>
          <div
            className="col-2 "
            style={{
              backgroundColor: "#DDDDDD",
              minHeight: "73vh",
              minWidth: "240px",
              marginLeft: "5vw",
              border: "1.5px solid rgb(0, 0, 0)",
            }}
          >
            <h3 style={{ marginTop: "2vh" }}>
              Filter&nbsp;&nbsp;
              <svg
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-funnel-fill"
                viewBox="0 0 18 18"
              >
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
              </svg>
            </h3>
            <div style={{ marginLeft: "1vw", marginTop: "2vh" }}>
              {" "}
              <h5 style={{ marginBottom: "2vh" }}>Type</h5>
              {/* select all  checkbox */}
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginBottom: "1vh",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    height: 20,
                    width: 20,
                  }}
                  type="checkbox"
                  isChecked={isCheckAll}
                  value={"selectAll"}
                  className="largerCheckbox"
                  onChange={handleSelectAll}
                />
                &nbsp;&nbsp;{"Select All"}
              </div>
              {processTypes
                // .filter((type) =>
                // ['Business Process',
                // 'E2E-Map',
                // 'E2E-Scenario',
                // 'E2E-Scenario Cluster',
                // 'Process Group'].some((filterValue)
                //  => type.includes(filterValue)))
                .map((type) => (
                  <div style={{ fontSize: 15, marginBottom: "0.7vh" }}>
                    <input
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        height: 20,
                        width: 20,
                      }}
                      type="checkbox"
                      id={type.name}
                      value={type.name}
                      className="largerCheckbox"
                      checked={checkedTypes.includes(type.name)}
                      onChange={handleCheckBox}
                    />
                    &nbsp;&nbsp;{type.name}
                  </div>
                ))}
            </div>
          </div>

          <div
            className="col-6 "
            id="containerPaginate"
            style={{
              minHeight: "73vh",
              marginLeft: "1.5vw",
            }}
          >
            <InputGroup style={{ flexWrap: "inherit" }}>
              <TextField
                id="outlined-basic"
                placeholder="Advance Search..."
                variant="outlined"
                size="small"
                value={searchValue}
                style={{ width: "inherit" }}
                onChange={async (e) => {
                  // this.updateState( e.target.value);
                  // this.setState({searchValue: e.target.value});
                  setSearchValue(e.target.value);
                }}
              />
              <InputGroup.Append>
                <Button
                  style={{ height: "fill-available" }}
                  onClick={async () => {
                    // this.setState({searchValue: this.state.searchValue});
                    // this.updateState();
                    setSearchValue(searchValue);
                  }}
                  variant="outline-secondary"
                >
                  Search
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <PaginateAdvance
              property={"name"}
              searchValue={searchValue}
              limit={5}
              orderBy={"name"}
              sort={sort}
              type={checkedTypes}
              // e2emap={this.props.e2emap}
              // processLibrary={this.props.processLibrary}
              {...props}
              setAttributes={setAttributes}
              setHasSearchResult={setHasSearchResult}
              setOccurences={setOccurences}
              setRelationships={setRelationships}
            />

            <br />
            <br />
          </div>
          <div className="col-3" style={{ marginLeft: "auto" }}>
            <h5
              style={{
                color: "GrayText",
                marginBottom: "2vh",
                textAlign: "center",
              }}
            >
              Sort items
              <ButtonGroup
                aria-label="First group"
                style={{ marginLeft: "1vw" }}
              >
                <Button
                  variant="secondary"
                  value={"ASC"}
                  className={sort === "ASC" ? " active" : ""}
                  onClick={() => setSort("ASC")}
                >
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-sort-alpha-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"
                    />
                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z" />
                  </svg>
                </Button>
                <Button
                  variant="secondary"
                  value={"DESC"}
                  className={sort === "DESC" ? " active" : ""}
                  onClick={() => setSort("DESC")}
                >
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-sort-alpha-down-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z" />
                    <path
                      fill-rule="evenodd"
                      d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"
                    />
                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z" />
                  </svg>
                </Button>
              </ButtonGroup>
            </h5>
            {/** PROPERTY PANEL */}
            {/* {props.showProperties && modelType != "Process" ? ( */}
            {hasSearchResult ? (
              <div
                // className="panel-group"
                style={{
                  minHeight: "73vh",
                  display: "flex",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <div className="panel-title"></div>
                  </div>
                  {/* <div id="collapse1" className="collapseDiv">> */}
                  <div>
                    <div>
                      <h5
                        style={{
                          color: "#222",
                          fontWeight: "bold",
                        }}
                      >
                        Properties
                      </h5>
                    </div>

                    <ul className="nav nav-pills">
                      <li
                        style={{
                          fontStyle: "italic",
                          marginRight: "5px",
                        }}
                      >
                        <a
                          className="active"
                          data-toggle="tab"
                          href="#attribute"
                        >
                          Attributes
                        </a>
                      </li>
                      |
                      <li
                        style={{
                          fontStyle: "italic",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <a data-toggle="tab" href="#occurence">
                          Occurrences
                        </a>
                      </li>
                      |
                      <li
                        style={{
                          fontStyle: "italic",
                          marginLeft: "5px",
                        }}
                      >
                        <a data-toggle="tab" href="#relationship">
                          Relationships
                        </a>
                      </li>
                    </ul>

                    <div
                      className="tab-content"
                      style={{
                        minHeight: "73vh",
                        border: "2px solid #000000",
                        marginRight: "15px",
                        overflow: "auto",
                        width: "22vw",
                        maxHeight: "73vh",
                        // height: props.propertyDivHeight,
                      }}
                    >
                      {attributes ? (
                        <AttributesPanel attributes={attributes} />
                      ) : (
                        <h5> No Result...</h5>
                      )}
                      <div id="occurence" className="tab-pane fade">
                        <div
                          className="table-responsive-xl"
                          style={{ tableLayout: "fixed" }}
                        >
                          <ul className="list-group">
                            <li className="list-group-item">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="attribute-name" scope="col">
                                      Object Occurences
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                              {occurences ? (
                                <div>
                                  {occurences.map((occ) => (
                                    <div key={uuidv4()}>
                                      <div
                                        style={{
                                          textDecoration: "underline",
                                          textUnderlinePosition: "under",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          history.push(
                                            `${props.path}/model/${occ.GUID}`
                                          );
                                        }}
                                      >
                                        {occ.name}
                                      </div>
                                      <div
                                        key={"t" + occ.GUID}
                                        style={{
                                          fontSize: "1.3vh",
                                          marginTop: "1vh",
                                        }}
                                      >
                                        {occ.Type}
                                      </div>
                                      <hr
                                        style={{
                                          width: "50",
                                          textAlign: "left",
                                          marginLeft: "0",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <h5> ... No Occurences Found ...</h5>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div id="relationship" className="tab-pane fade">
                        <div className="table-responsive-xl">
                          <ul className="list-group">
                            <li className="list-group-item">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="attribute-name" scope="col">
                                      Object Relationships
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                              {
                                <div>
                                  {relationships.map((rel) => (
                                    <div key={uuidv4()}>
                                      <div
                                        style={{
                                          textDecoration: "underline",
                                          textUnderlinePosition: "under",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          history.push(
                                            `${props.path}/object/${rel.GUID}`
                                          );
                                        }}
                                      >
                                        {rel.name}
                                      </div>
                                      <div
                                        key={"t" + rel.GUID}
                                        style={{
                                          fontSize: "1.3vh",
                                          marginTop: "1vh",
                                        }}
                                      >
                                        {rel.Type}
                                      </div>
                                      <hr
                                        style={{
                                          width: "50",
                                          textAlign: "left",
                                          marginLeft: "0",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
  // }
}

// export default AdvanceSearch;
