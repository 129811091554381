import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Common from "./Common";
import slider1 from "../images/Business Flows Illustration.jpg";
import slider2 from "../images/bpExperts_domain.jpg";
import slider3 from "../images/Pyramid.jpg";
import { Alert } from "react-bootstrap";
import { requestProlongation } from "../actions";
import { Dropdown } from "react-bootstrap";

const Home = (props) => {
  const history = useHistory();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      if (!props.user.id) {
        alert("No signedin User, logging out..");
        localStorage.clear();
        history.push("/login");
        return;
      }
      // IN case no database has been assigned to user, assign bpexperts per default
      // if (!props.user.databases || props.user.databases.length === 0) {
      //  // alert("No database assigned to User, logging out..");
      //   localStorage.clear();
      //   history.push("/login");
      //   return;
      // }
      let database = localStorage.getItem("db");
      if (database && database !== props.user.mainDatabase) {
        try {
          props.setUser({
            ...props.user,
            mainDatabase: database,
          });
          history.push(`/home/${database}`);
        } catch (error) {
          console.error("Error parsing JSON from decrypted string:", error);
        }
      }
    }
    setFirstRender(false);
  }, []);
  const getCurrentDate = () => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    return year + "-" + month + "-" + date;
  };
  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

  const [alertBox, setAlertBox] = useState({
    show: false,
    variant: "warning",
    msg: "",
  });

  const resetAlertBox = () => {
    setAlertBox({
      show: false,
      variant: "warning",
      msg: "",
    });
  };
  const accountExtesnionRequest = async () => {
    let res = await requestProlongation(props.user.email);
    if (res.success) {
      setAlertBox({
        variant: "warning",
        msg: res.msg,
      });
    } else {
      setAlertBox({
        variant: "danger",
        msg: "Internal Server problem, please try again later",
      });
    }
    setTimeout(resetAlertBox, 4000);
  };
  let isSafari = false;
  var sBrowser,
    sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    isSafari = true;
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  return (
    <>
      <div
        className="d-flex"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        {/* Disclaimer for  for safari browser  */}
        {isSafari ? (
          <div
            className="alert alert-danger alert-dismissible fade show col-10 "
            role="alert"
          >
            <p className="mb-0" style={{ textAlign: "center" }}>
              Right now, you are using the Apple Safari browser. For a better
              experience, Kindly use Google Chrome or Mozilla Firefox.
            </p>
          </div>
        ) : null}

        <div
          className="alert alert-dark alert-dismissible fade show col-10 "
          role="alert"
        >
          <p style={{ textAlign: "center" }}>
            {props?.user?.subscription === "trial" ? (
              // if sunscription is trial show the numbers of days left to expire account
              //  with request for extension button
              <>
                Hello,&nbsp;{props?.user?.firstName}&nbsp;
                {props?.user?.lastName}&nbsp;
                <strong>
                  {diffDays(
                    new Date(props?.user?.expirationDate),
                    new Date(getCurrentDate())
                  )}
                  &nbsp;
                </strong>
                days until your {props?.user?.subscription} subscription
                expires. For extension, please request to&nbsp;
                <button
                  className="badge badge-dark"
                  type="submit"
                  onClick={accountExtesnionRequest}
                >
                  &nbsp;Extend Account&nbsp;
                </button>
              </>
            ) : props?.user?.subscription === "ultimatePlus" ? (
              <>
                {/* welcome note for with current subscription (for ultimate-Plus) */}
                Hello,&nbsp;{props?.user?.firstName}&nbsp;
                {props?.user?.lastName}. Welcome to Business flows
                <strong> Ultimate plus </strong>edition.
              </>
            ) : (
              <>
                {/* welcome note for with current subscription (for Ultimate ) */}
                Hello,&nbsp;{props?.user?.firstName}&nbsp;
                {props?.user?.lastName}. You have an
                <strong>&nbsp;{props?.user?.subscription}&nbsp;</strong>
                subscription.
              </>
            )}
          </p>
          {"\n"}
          {/* if user is not ultimatePlus than show to update subscription */}
          {props?.user?.subscription === "ultimatePlus" ? (
            <div></div>
          ) : (
            <p className="mb-0" style={{ textAlign: "center" }}>
              For the subscription upgrade, kindly&nbsp;
              <button className="badge badge-dark">
                {" "}
                <a
                  className="text-light bg-dark"
                  href="mailto:sales@bpexperts.de?subject=Business Flows Browser Request for Update Subscription"
                >
                  &nbsp;Contact us&nbsp;
                </a>
              </button>
            </p>
          )}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {props.user.mainDatabase || "Select Database"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {props.user.databases.map((database) => (
              <Dropdown.Item
                onClick={(e) => {
                  const db = e.target.innerText;
                  props.setUser({
                    ...props.user,
                    mainDatabase: db,
                  });
                  history.push(`/home/${database}`);

                  localStorage.setItem("db", db);
                  history.go(0);
                }}
              >
                {database}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {
          <Alert
            className="alert alert-info alert-dismissible col-10 fade inn"
            align="center"
            variant={alertBox.variant}
            show={alertBox.show}
          >
            {alertBox.msg}
          </Alert>
        }
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Common
          name="Boost your business transformation with"
          visitBF={`${props.path}/model/${props.e2eMap.GUID}`}
          visitRE={`${props.path}/object/${props.e2eMap.GUID}`}
          visitChatBot={props.path}
          btnameBF="Access Business Flows"
          btnameRE="Relationship Explorer"
          btnamechatBot="Business Flows Assistant"
          slider1={slider1}
          slider2={slider2}
          slider3={slider3}
          
          {...props}
        />
      </div>
    </>
  );
};

export default Home;
