import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { FaLink, FaInfoCircle } from "react-icons/fa";
import ShowMoreText from "react-show-more-text";
import e2eMapDump from "../../assets/e2eMap-dump";
import libraryDump from "../../assets/library-dump";
import {
  getAttributesByKey,
  isEntryinData,
  getShortName,
} from "../../utils/accessMapFunctions";
import {
  getProcessAttributesByPartialName,
  getProcessOccurences,
  getProcessRelationships,
  getProcessBPMN,
} from "../../actions";

class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.someRefName = React.createRef();
    this.onSingleClickModel = this.onSingleClickModel.bind(this);
  }

  state = {
    hasBPMN: false,
    loading: true,
  };

  async componentDidMount() {
    // const hasBPMN = await this.hasAssignment();
    // this.setState({ hasBPMN, loading: false });
    // const bpmn = await getProcessBPMN(this.props.guid);
    // if (bpmn) this.setState({ hasBPMN: true, loading: false });
    // else this.setState({ hasBPMN: false, loading: false });
  }

  async hasAssignment() {
    // const bpmn = await getProcessBPMN(this.props.guid);
    // if (bpmn) this.setState({ hasBPMN: true, loading: false });
    // else this.setState({ hasBPMN: false, loading: false });
  }

  async onSingleClickModel() {
    const attributes = await getProcessAttributesByPartialName(this.props.guid);
    this.props.setAttributes(attributes);

    const occs = await getProcessOccurences(this.props.guid);
    this.props.setOccurences(occs);

    const rels = await getProcessRelationships(this.props.guid);
    this.props.setRelationships(rels);
    console.log(this.props.hasBpmn.success);
  }

  render() {
    const { loading, hasBPMN } = this.state;
    const { description, guid, name, type, url, path } = this.props;

    let isURL = false;
    let pointerEvents = "";
    let isGUID = true;

    if (description) {
      try {
        const urlInstance = new URL(description);
        isURL =
          urlInstance.protocol === "http:" || urlInstance.protocol === "https:";
      } catch (_) {
        isURL = false;
      }
    }

    if (guid) {
      if (!guid.includes("-") && !isURL) {
        pointerEvents = "none";
        isGUID = false;
      } else if (!hasBPMN && !isURL) {
        pointerEvents = "none";
      }
    }

    // if (loading) {
    //   return <div>Loading...</div>;
    // }

    return (
      <div
        style={{
          backgroundColor: "#eee",
          height: "auto",
          boxShadow: "5px 5px 5px rgb(107, 99, 99)",
          alignItems: "flex-start",
          borderRadius: "3px",
          position: "relative",
          padding: 10,
          marginBottom: "1.5vh",
          wordWrap: "break-word",
        }}
      >
        <div
          style={{
            float: "right",
            marginLeft: "0.4em",
            order: 1,
            cursor: "pointer",
            pointerEvents,
          }}
        >
          {url ? (
            <Link
              style={{
                cursor: "pointer",
                pointerEvents: "auto",
              }}
              to={`${path}/external-link/${url}`}
              target="_blank"
            >
              <FaLink />
            </Link>
          ) : null}

          <div
            style={{
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={this.onSingleClickModel}
          >
            <FaInfoCircle />
          </div>
        </div>

        <div>
          {!this.props.hasBpmn.success &&
          type !== "E2E-Scenario Cluster" &&
          type !== "Process Group" ? (
            <div
              onClick={() => {
                const node = {
                  id: guid,
                  guid,
                  shortName: getShortName(name),
                  fullName: name,
                  type,
                  description,
                  children: [],
                  isLink: false,
                  url,
                  style: {
                    background: "#FFFFFF",
                  },
                };
                this.props.setNodeDatum(node);
                this.props.history.push(`${path}/object/${guid}`);
              }}
            >
              <h5
                className="factSheetFullName disableSelection"
                style={{
                  margin: "0 0 .5em",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                {name}
              </h5>
            </div>
          ) : (
            <Link to={`${path}/model/${guid}`} target="_blank">
              <h5
                className="factSheetFullName disableSelection"
                style={{
                  margin: "0 0 .5em",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                {name}
              </h5>
            </Link>
          )}
          <p
            style={{
              fontSize: 15,
              margin: 2,
              color: "rgb(111 111 111)",
              fontWeight: "bold",
            }}
          >
            {type}
          </p>

          <p
            style={{
              fontSize: 16,
              margin: 2,
              color: "#606362",
              textAlign: "justify",
              textJustify: "inter-word",
              pointerEvents: "all",
            }}
          >
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {description}
            </ShowMoreText>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(ItemCard);
