import ReactPaginate from "react-paginate";
import React, { useEffect, useState, useCallback } from "react";
import ItemCard from "./ItemCard";
import "../../index.css";
import { advanceSearch, getProcessBPMN } from "../../actions";

function SearchResult({ searchResult, className, props }) {
  // console.log(props.setNodeDatum);
  if (searchResult.length === 0) {
    return (
      <div className="alert alert-warning" role="alert">
        No results.
      </div>
    );
  } else {
    props.setHasSearchResult(true);
  }
  // change repostiry name to serach result
  return (
    <div className={className}>
      {searchResult?.map((repository) => (
        <div className="repository-entry">
          {/* <h4>{repository.name}</h4> */}
          <ItemCard
            {...props}
            name={repository.name}
            description={repository.Description}
            type={repository.Type}
            guid={repository.GUID}
            setNodeDatum={props.setNodeDatum}
            setAttributes={props.setAttributes}
            url={repository.URL}
            hasBpmn={repository.hasBpmn}
          />
        </div>
      ))}
    </div>
  );
}

export default function PaginateAdvanceSearch(props) {
  const [searchResult, setSearchResult] = useState([]);
  const [pageOffset, setPageOffset] = useState(0); //skip 2 for next page
  const [pageCount, setPageCount] = useState(0);
  const [apiError, setApiError] = useState(null);
  const [totalResult, setTotalResult] = useState(0);
  const [skip, setSkip] = useState(0);

  // set page numbrt to 1 if search value or filter type change
  useEffect(async () => {
    setSkip(0);
    setPageOffset(0);
  }, [props.type, props.searchValue]);

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await advanceSearch(
          props.property,
          props.searchValue,
          skip,
          props.limit, // page-offset
          props.orderBy,
          props.sort,
          props.type
        );
      } catch (error) {
        setApiError("Failed to fetch data");
        setSearchResult([]);
        setPageCount(0);
        return;
      }

      let responseJson = response;
      setTotalResult(responseJson.total);

      if (!responseJson.success) {
        setApiError(responseJson.msg);
        setSearchResult([]);
        setPageCount(0);
        return;
      }

      try {
        const updatedArray1 = await Promise.all(
          responseJson.content.map(async (item) => ({
            ...item,
            hasBpmn: await getProcessBPMN(item.GUID),
          }))
        );
        const newPageCount = Math.ceil(responseJson.total / props.limit);
        setSearchResult(updatedArray1);
        setPageCount(newPageCount);
        console.log(updatedArray1);
      } catch (error) {
        setApiError("Failed to process BPMN data");
      }
    };

    fetchData();
  }, [
    pageOffset,
    // instead of using all props using specific props e.g. props.searchValue
    // it avoids multiple calls
    props.property,
    props.searchValue,
    skip,
    props.limit,
    props.orderBy,
    props.sort,
    props.type,
  ]);

  // useEffect(async () => {
  //   let response = await advanceSearch(
  //     props.property,
  //     props.searchValue,
  //     skip,
  //     props.limit, //page-offset
  //     props.orderBy, //
  //     props.sort,
  //     props.type
  //   );
  //   let responseJson = response;
  //   setTotalResult(responseJson.total);

  //   if (!responseJson.success) {
  //     setApiError(responseJson.msg);
  //     setSearchResult([]);
  //     setPageCount(0);
  //     return;
  //   }
  //   const updatedArray1 = response.content.map((item) => ({
  //     ...item,
  //     hasBpmn: getProcessBPMN(item.GUID),
  //   }));
  //   const newPageCount = Math.ceil(response.total / 5);
  //   setSearchResult(response.content);
  //   setPageCount(newPageCount);
  //   console.log(updatedArray1);
  // }, [
  //   pageOffset,
  //   // instead of using all props using speccific props e.g. props.searchValue
  //   // it avoids mutltiple call
  //   props.property,
  //   props.searchValue,
  //   skip,
  //   props.limit,
  //   props.orderBy,
  //   props.sort,
  //   props.type,
  // ]);

  const handlePageChange = (event) => {
    setSkip(event.selected * 5);
    setPageOffset(event.selected);
  };

  return (
    <div
      className="disableSelection"
      style={{ justifyContent: "center", minHeight: "inherit" }}
    >
      <p> Total {totalResult} matches found.</p>
      {apiError && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {apiError}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <SearchResult
        className="listing"
        searchResult={searchResult}
        props={props}
        {...props}
      />
      <div
        className="m-auto test1212"
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          left: "50%",
          bottom: 0,
          webkitTransform: "translateX(-50%)",
          transform: "translateX(-50%)",
        }}
      >
        <ReactPaginate
          style={{
            justifyContent: "center !important",
            //    position: "absolute", bottom: 0 ,transform: "translate(-50%, -50%)"
          }}
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageOffset}
          initialPage={0}
        />
      </div>
    </div>
  );
}
