import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Dropdown, InputGroup, Button, Form, FormText } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { getShortName, searchText_ } from "../utils/accessMapFunctions";
import { useLocation } from "react-router-dom";
import "../index.css";
import { FaDatabase } from "react-icons/fa";
const Navbar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [isRelationExplorer, setIsRelationExplorer] = useState(false);

  useEffect(() => {
    setIsRelationExplorer(location.pathname.includes("object"));
  }, [location]);

  if (props.userEmail && props.user.mainDatabase) {
    return (
      <>
        <div className={"container-fluid nav_bg my-3 header-section"}>
          {/* Space between navbar and sign up form*/}
          <div className="row">
            <div className="col-10 mx-auto" style={{ paddingBottom: 0 }}>
              <nav className=" navbar navbar-expand-lg navbar-light bg-light">
                <NavLink className="navbar-brand" to={`/home`}>
                  <img src={"/Business Flows_Logo_RGB.png"} />

                  {/* <h1>bpExperts</h1> */}
                </NavLink>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                    {history.location.pathname.includes("object") ? (
                      <RelationshipExplorerSearch {...props} />
                    ) : history.location.pathname.includes("advance-search") ? (
                      <AdvanceSearch {...props} />
                    ) : (
                      <ExtendedSearch {...props} />
                    )}

                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {props.userEmail}
                      </Dropdown.Toggle>
                      {props.userRole == "admin" ? (
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => history.push("/my-account")}
                          >
                            My Account
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => history.push("/admin")}>
                            Admin Portal
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={
                              () => history.push("/changepassword")
                              // & history.go(0)
                            }
                          >
                            Change Password
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={
                              () =>
                                history.push(
                                  `${props.path}/object/${props.e2eMap.GUID}`
                                ) // & history.go(0)
                            }
                          >
                            Relationship Explorer
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              history.push("/login");
                              history.go(0);
                            }}
                          >
                            Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      ) : (
                        <div className="col-10 mx-auto">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => history.push("/my-account")}
                            >
                              My Account
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={
                                () => history.push("/changepassword") //& history.go(0)
                              }
                            >
                              Change Password
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={
                                () => history.push(`${props.path}/object`) //& history.go(0)
                              }
                            >
                              Relationship Explorer
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://forms.office.com/r/R1gx7aFBni"
                            >
                              Maturity Survey
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              style={{ color: "red" }}
                              onClick={() => {
                                localStorage.clear();
                                history.push("/login");
                                history.go(0);
                              }}
                            >
                              Log Out
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </div>
                      )}
                    </Dropdown>
                  </ul>
                </div>
              </nav>

              <div
                title="Selected Database."
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "15px",
                }}
              >
                <div style={{ paddingRight: "5px" }}>
                  <FaDatabase color="#F0E631" size={20} />
                </div>
                <span style={{ color: "#777", fontWeight: "lighter" }}>
                  {props.path.substring(1)}
                </span>
              </div>

              {location.pathname.includes("advance-search") ? null : (
                <div>
                  <hr style={{ marginBottom: "10px", marginTop: "4px" }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="container-fluid nav_bg my-3">
        <div className="row">
          <div className="col-10 mx-auto">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <NavLink className="navbar-brand" to="/">
                <img src={"/Business Flows_Logo_RGB.png"} />
                {/* <h1>bpExperts</h1> */}
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    );
  }
};

const SimpleSearch = () => {
  const options = ["alamo", "alimo"];

  return (
    <Typeahead
      clearButton={false}
      id="selections-example"
      labelKey="name"
      onInputChange={(text, e) => {}}
      options={options}
      placeholder="Search model..."
    />
  );
};

const RelationshipExplorerSearch = (props) => {
  const [filterBy, setFilterBy] = useState("callback");
  const [results, setResults] = useState([]);

  const handleChange = async (searchValue) => {
    let options = [];
    searchText_(searchValue, [props.e2eMap, props.processLibrary], 20, options);

    setResults(options);
  };

  const filterByCallback = (option, props) =>
    option?.guid.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
    option?.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

  const filterByFields = ["name"];
  const history = useHistory();

  return (
    <InputGroup style={{ justifyContent: "end" }}>
      <Typeahead
        filterBy={filterBy === "callback" ? filterByCallback : filterByFields}
        clearButton={false}
        id="custom-filtering-example"
        labelKey="name"
        options={results}
        placeholder="Search in relationship explorer..."
        onInputChange={(text, e) => {
          handleChange(text);
        }}
        renderMenuItemChildren={(option) => (
          <div
            onClick={(e) => {
              const node = {
                id: option.guid,
                guid: option.guid,
                shortName: getShortName(option.name), //this.getShortName(node.name),
                fullName: option.name,
                type: option.type, //node.Type,
                description: option.description, //node.Description,
                children: [],
                isLink: false,
                style: {
                  background: "#FFFFFF",
                },
              };
              props.setNodeDatum(node);
            }}
          >
            {option.name}

            <div>
              <small>{option.type}</small>
            </div>
          </div>
        )}
        positionFixed
        style={{ width: "30rem" }}
      />
      <Button
        style={{
          position: "absolute",
          textDecoration: "none",
          color: "GrayText",
        }}
        onClick={() => history.push(`${props.path}/advance-search`)}
        variant="link"
      >
        Advanced
      </Button>
    </InputGroup>
  );
};

const AdvanceSearch = (props) => {
  // const [searchValue, setSearchValue] = useState("");
  // props.setAdvanceSearchResult("");
  // props.setAdvanceSearchResult(searchValue);

  return (
    <InputGroup style={{ flexWrap: "inherit" }}>
      {/* <TextField
        id="outlined-basic"
        placeholder="Advance Search..."
        variant="outlined"
        size="small"
        value={searchValue}
        style={{ width: "30rem" }}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <InputGroup.Append>
        <Button
          style={{ height: "fill-available", marginRight: "1vw" }}
          onClick={() => 
            {
              props.setAdvanceSearchResult("");
              props.setAdvanceSearchResult(searchValue);
              
            }
            }

          variant="outline-secondary"
        >
          Search
        </Button>
      </InputGroup.Append> */}
    </InputGroup>
  );
};

const ExtendedSearch = (props) => {
  const [filterBy, setFilterBy] = useState("callback");
  const [results, setResults] = useState([]);

  const handleChange = (searchValue) => {
    var options = [];
    searchText_(searchValue, [props.e2eMap, props.processLibrary], 20, options);

    if(props.user.subscription === "trial"){
      options = options.filter(elem => elem.inTrial === true)
    }
    setResults(options);
  };

  const filterByCallback = (option, props) =>
    option?.guid.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
    option?.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

  const filterByFields = ["name"];
  const history = useHistory();

  return (
    <InputGroup style={{ justifyContent: "end" }}>
      <Typeahead
        filterBy={filterBy === "callback" ? filterByCallback : filterByFields}
        clearButton={false}
        id="custom-filtering-example"
        labelKey="name"
        options={results}
        placeholder="Search model..."
        onInputChange={(text, e) => {
          handleChange(text);
        }}
        renderMenuItemChildren={(option) => (
          <div
            onClick={(e) => {
              history.push(`${props.path}/model/${option.guid}`);
            }}
          >
            {option?.name}

            <div>
              <small>{option?.type}</small>
            </div>
          </div>
        )}
        positionFixed
        style={{ width: "30rem" }}
      />
      <Button
        style={{
          position: "absolute",
          textDecoration: "none",
          color: "GrayText",
        }}
        onClick={() => history.push(`${props.path}/advance-search`)}
        variant="link"
      >
        Advanced
      </Button>
    </InputGroup>
  );
};

export default Navbar;
