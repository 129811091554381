var env = "PROD";
var host, frontendPort, backendPort;

switch (env) {
  case "DEV":
    host = "http://localhost:";
    backendPort = 3001;
    frontendPort = 3000;
    break;
  case "PROD":
    host = "https://businessflows.bpexperts.de:";
    backendPort = 443;
    frontendPort = 443;
    break;
  case "TEST":
    host = "https://test-businessflows.bpexperts.de:";
    backendPort = 443;
    frontendPort = 443;
    break;
  default:
}

export default {
  host,
  frontendPort,
  backendPort,
  env,
};
